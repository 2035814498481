import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START FILE FORM')
  }

  submit(event) {
    event.preventDefault()

    $.ajax({
      url: this.data.get('url'),
      dataType: 'script',
      type: 'POST',
      data: new FormData(this.element),
      processData: false,
      contentType: false,
      success: () => {
        console.log('success')
      },
      error: err => {
        console.log(`Erro ao acessar o serviço. ${err}`)
      }
    })
  }
}
