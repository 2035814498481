import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  verifyUploadFields() {
    //get all file fields from form
    const divRemoveFiles = $('input[type=file]').parent().next()

    if (divRemoveFiles.length > 0) {
      //check each file field if contains html
      divRemoveFiles.each(function (index) {
        if ($(this).html().trim()) {
          $(this).show()
          $(this).prev().hide()
        } else {
          $(this).hide()
          $(this).prev().show()
        }
      })
    }
  }

  hideUploadField(e) {
    e.preventDefault()

    const $link = $(e.target)
    const divRemoveFile = $link.parent()
    const divUploadFile = $link.parent().prev()

    divRemoveFile.hide()
    divUploadFile.show()
  }

  initialize() {
    this.verifyUploadFields()
  }

  connect() {
    console.log('START FILE')


  }

}