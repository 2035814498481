import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = ['select', 'selectProcedencia' ]

  verifSelect(e) {
    let options = ['SIL', 'Outro']
    let select = e.target
    let $group = $(select).parent().next()
    let selectedValue = $('option:selected', select).val()

    if (options.includes(selectedValue)) {
      $group.show()
    } else {
      $group.hide()
    }
  }

  verifSelectProcedencia(e) {
    const select = e.target
    const $silvestre    = $(select).parent().next()
    const $bioterio     = $(select).parent().next().next()
    const $outroProjeto = $(select).parent().next().next().next()
    const $comercial    = $(select).parent().next().next().next().next()
    const $outro        = $(select).parent().next().next().next().next().next()
    const selectedValue = $('option:selected', select).val()

    if (selectedValue === 'SIL') {
      $silvestre.show()
    } else {
      $silvestre.hide()
    }

    if (selectedValue === 'BIO') {
      $bioterio.show()
    } else {
      $bioterio.hide()
    }

    if (selectedValue === 'PRO') {
      $outroProjeto.show()
    } else {
      $outroProjeto.hide()
    }

    if (selectedValue === 'COM') {
      $comercial.show()
    } else {
      $comercial.hide()
    }

    if (selectedValue === 'OUT') {
      $outro.show()
    } else {
      $outro.hide()
    }
  }

  connect() {
    console.log('START SELECT')

    const that = this
    this.selectTargets.forEach((el) => {
      el.addEventListener('change', this.verifSelect)
      that.trigger(el, 'change')
      el.removeEventListener('change', this.verifSelect)
    })

    if (this.hasSelectProcedenciaTarget) {
      this.selectProcedenciaTarget.addEventListener('change', this.verifSelectProcedencia)
      this.trigger(this.selectProcedenciaTarget, 'change')
      this.selectProcedenciaTarget.removeEventListener('change', this.verifSelectProcedencia)
    }
  }

}