import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START DRAFT SAVE')
    this.timeout = null
    this.duration = this.data.get('duration') || 1000
  }

  save(e) {
    if (this.data.get('mode') === 'NEW') {
      this.create()
    } else {
      this.update(e)
    }
  }

  create() {
    console.log('saving...')
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      $.ajax({
        url: this.data.get('url'),
        dataType: 'script',
        type: 'POST',
        data: new FormData(this.element),
        processData: false,
        contentType: false,
        success: () => {
          console.log('success')
        },
        error: err => {
          console.log(`Erro ao acessar o serviço. ${err}`)
        }
      })
    }, this.duration)
  }

  update(e) {
    console.log('saving...')
    const url = encodeURI(`${this.data.get('url')}?field=${e.currentTarget.name}&value=${e.currentTarget.value}`)
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      $.ajax({
        url,
        dataType: 'script',
        type: 'PUT',
        success: () => {
          console.log('success')
        },
        error: err => {
          console.log(`Erro ao acessar o serviço. ${err}`)
        }
      })
    }, this.duration)
  }

}
