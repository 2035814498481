import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = ['my'];

  toggle(e) {
    e.preventDefault();
    $(this.myTarget).toggle();
  }

  connect() {
    console.log('START OTHER FIELD');
    
    if (this.myTarget.value === '') {
      $(this.myTarget).hide();
    } else {
      $(this.myTarget).show();
    }

  }

}