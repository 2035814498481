import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = ['hidden', 'selector']

  hide() {
    let options = ['ad referendum em']
    const $hidden = $(this.hiddenTarget)

    if (options.includes(this.selectorTarget.value)) {
      $hidden.show()
    } else {
      $hidden.hide()
    }
  }

  connect() {
    console.log('START SELECT HIDE')
    this.hide()
  }

}