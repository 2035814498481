import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {

  connect() {
    console.log('START AUTOMATIC SAVE RESPOSTA REPORT')
    this.automaticSave()
  }

  disconnect() {
    this.clearAutomaticSave()
  }

  automaticSave() {
    this.refreshTimer = setInterval(()=> {
      this.save()
    }, 300000)
  }

  clearAutomaticSave() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  save(e) {
    let auto
    if (e) {
      e.preventDefault()
      auto = e.target.dataset.auto
    } else {
      auto = 'true'
    }

    const isUpdateOperation = this.data.get('update')
    const respostaReportId = this.data.get('resposta-report-id')
    const reportId = this.data.get('report-id')
    const projectId = this.data.get('project-id')

    let data = $('.card-body > form').serializeArray()
    data.push({ name: "automatic_save", value: auto })

    if(isUpdateOperation == 'true') {
      // update
      $.ajax({
        url: `/projects/${projectId}/reports/${reportId}/resposta_reports/${respostaReportId}.js`,
        type: 'PATCH',
        data: $.param(data)
      })
      .done(() => console.log('success'))
      .fail((xhr, status, error) => console.log("error", xhr.responseText))
      .always(() => console.log("complete"))
    
    } else {
      // create
      $.ajax({
        url: `/projects/${projectId}/reports/${reportId}/resposta_reports.js`,
        type: 'POST',
        data: $.param(data)
      })
      .done(() => console.log('success'))
      .fail((xhr, status, error) => console.log("error", xhr.responseText))
      .always(() => console.log("complete"))
    }
  }
}