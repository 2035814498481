import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = [ 'my' ]

  connect() {
    console.log('START INLINE EDIT')
  }

  edit(e) {
    const url = this.data.get('url')

    let el = e.target
    let esc = (e.which == 27)
    let newLine = (e.which == 13)

    let id = el.getAttribute('data-id')
    let field = el.getAttribute('data-field')
    
    /* resource[0] = recurso, resource[1] = atributo
     * utilizado para montar o data a ser enviado:
     *
     * data {
     *  recurso: {
     *    atributo: value
     *  }
     * }
    */

    let resource = field.split('-')

    if (esc) {
      // restore state
      document.execCommand('undo')
      el.blur()

    } else if (newLine) {
      // save
      let data = {}
      data[resource[0]] = {} 
      data[resource[0]][resource[1]] = el.innerHTML.trim()

      $.ajax({
        url: `${url}/${id}.json`,
        type: 'PATCH',
        data: data
      })
      .done(function() {
        console.log("success")
      })
      .fail(function (xhr, status, error) {
        console.log("error", xhr.responseText)
      })
      .always(function() {
        console.log("complete")
      })

      el.blur()
      e.preventDefault()
    }

  }

}